import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

const PortfolioItem = (props) => {
  const item = props.item
  const title = item.frontmatter.title || item.fields.slug
  const renderImage = () => {
    if (!item.frontmatter.featuredImage) return;

    return (
      <Link to={item.fields.slug}>
        <Img
          className="screenshot"
          fluid={item.frontmatter.featuredImage.childImageSharp.fluid}
        />
      </Link> 
    )
  }
  const renderTags = () => {
    if (!item.frontmatter.tags) return; 

    return (
      <ul className="tag-list">
      {item.frontmatter.tags.map((tag, index) => { 
        return <li key={`tag-${index}`} className="tag-list-item">{tag}</li>
      })}
      </ul>
    )
  }

  return (
    <li className="portfolio-item" >
      <div className="portfolio-item__image">
        {renderImage()}
      </div> 
      <div className="portfolio-item__body">
        <h2 className="portfolio-item__title">{title}</h2>
        {renderTags()}
        <p
          className="portfolio-item__summary"
          dangerouslySetInnerHTML={{
            __html: item.frontmatter.summary || item.excerpt,
          }}
        />
        <p>
          <Link to={item.fields.slug} className="portfolio-item__link button" aria-label={`Learn more about ${title}`} >
            Learn More → 
          </Link>
        </p> 
      </div>
    </li>
  )
}

export default PortfolioItem