import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PortfolioItem from "../components/portfolio-list-item"

const PortfolioIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const items = data.allMarkdownRemark.nodes

  if (items.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title=" " />
        <p>
          No portfolio items found. Add markdown posts to "content/portfolio".
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title=" " />
      <p className="page-intro">
        Select projects I've worked on over the past few years.
      </p>
      <ol className="portfolio-list">
        {items.map((item, index) => { 
          return <PortfolioItem key={item.fields.slug} item={item} />
        })}
      </ol>
    </Layout>
  )
}

export default PortfolioIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: {fields: frontmatter___order, order: ASC}
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          tags
          summary
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
